import { useToast as useToastPrime } from "primevue/usetoast";

export type Toast = {
  title?: string;
  description?: string;
  variant: "default" | "loading" | "error" | "success";
  icon?: string;
  duration?: number;
};

const primeToastSeverity = (
  variant: Toast["variant"]
):
  | "info"
  | "error"
  | "success"
  | "warn"
  | "secondary"
  | "contrast"
  | undefined => {
  switch (variant) {
    case "default":
      return "info";
    case "loading":
      return "secondary";
    case "error":
      return "error";
    case "success":
      return "success";
    default:
      return "success";
  }
};

export const useToast = () => {
  const toaster = useToastPrime();

  const dismiss = () => {
    toaster.removeAllGroups();
  };

  const toast = (toast: Partial<Toast>) => {
    const body = {
      variant: toast.variant || "default",
      ...toast,
    };

    toaster.add({
      severity: primeToastSeverity(body.variant),
      summary: body.title,
      detail: body.description,
      life: body.duration || 3000,
    });

    return body;
  };
  const toastError = (message: string) => {
    toast({
      variant: "error",
      title: "Erreur",
      description: message,
      icon: "pi pi-exclamation-triangle",
    });
  };

  toast.error = toastError;

  const toastSuccess = (message: string) => {
    toast({
      variant: "success",
      title: "Succès",
      description: message,
      icon: "pi pi-check",
    });
  };

  toast.success = toastSuccess;

  return {
    toast,
    dismiss,
  };
};
